




























































































































































import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DeliveryOrderData,
  PickingListData,
} from "@/models/interface/salesOrder.interface";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { contactServices } from "@/services/contact.service";
import { salesOrderServices } from "@/services/salesorder.service";
import { WrappedFormUtils } from "ant-design-vue/types/form/form";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class ListDeliveryOrder extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  moment = moment;
  form!: WrappedFormUtils;
  totalElements = 0 as number;
  page = 1 as number;
  limit = 10 as number;
  search = "" as string;
  formRules = {
    pickingListNumber: {
      label: "Picking List Number",
      name: "pickingListNumber",
      placeholder: "Type for more data",
      decorator: [
        "pickingListNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    deliveryOrderNumber: {
      label: "Delivery Order Number",
      name: "deliveryOrderNumber",
      placeholder: "lbl_type_to_find_placeholder",
      decorator: [
        "deliveryOrderNumber",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: [
        "customerName",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      label: "Date",
      name: "Date",
      placeholder: "Insert date",
      decorator: [
        "date",
        {
          rules: [
            {
              required: false,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
  };
  columnsTable = [
    {
      title: "Delivery Order Number",
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Delivery Order Date",
      dataIndex: "date",
      key: "date",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Expedition",
      dataIndex: "supplierExpeditionName",
      key: "supplierExpeditionName",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Reference Number",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Picking List Number",
      dataIndex: "pickingListNo",
      key: "pickingListNo",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: "Journal Number",
      dataIndex: "journalNumber",
      key: "journalNumber",
      width: 150,
      scopedSlots: { customRender: "clickColumn" },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      width: 100,
      scopedSlots: { customRender: "isNull" },
    },
  ] as ColumnTableCustom[];

  loading = {
    deliveryOrder: false as boolean,
    find: false as boolean,
    customer: false as boolean,
    pickingList: false as boolean,
  };
  modelForm = {
    date: [] as any,
  };
  dataListPickingList: PickingListData[] = [];

  dataListDeliveryOrder = [] as DeliveryOrderData[];
  dataListCustomer = [] as ContactData[];
  dataSource = [] as DeliveryOrderData[];

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  clearItems(): void {
    this.form.resetFields();
    this.modelForm.date = [];
  }

  handleClickColumn(record, _objColumnNameValue) {
    this.$router.push("/generaljournal/journal/detail/" + record.journalId);
  }
  createNew(): void {
    this.$router.push({ name: "sales.delivery-order.create" });
  }

  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  beforeCreate(): void {
    this.form = this.$form.createForm(this, {
      name: "DeliveryOrderFormFinder",
    });
  }

  mounted(): void {
    this.getCustomer("");
    this.getDeliveryOrder("");
    this.getPickingList("", "");
  }

  created() {
    this.columnsTable.push({
      title: this.$root.$t("lbl_action").toString(),
      dataIndex: "operation",
      key: "operation",
      scopedSlots: { customRender: "operation" },
      button: ["view"],
      width: 120,
      align: "center",
      fixed: "right",
    });
  }

  responsePageSizeChange(response: ResponsePagination) {
    this.limit = response.size;
    this.page = 1;
    this.findData(true);
  }

  responseCurrentPageChange(response: ResponsePagination) {
    this.page = response.page;
    this.findData(true);
  }
  onRangePickerChange(dates: Array<any>): void {
    if (!dates.length) {
      this.modelForm.date = undefined;
    }
  }

  handleClickEdit(data): void {
    this.$router.push({
      name: "sales.delivery-order.edit",
      params: { id: data.data.id },
    });
  }

  getCustomer(value: string): void {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `customer~true`,
    };

    if (value) {
      params.search =
        params.search + `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    }
    this.loading.customer = true;
    contactServices
      .listContactData(params)
      .then(data => {
        this.dataListCustomer = data.data;
      })
      .finally(() => (this.loading.customer = false));
  }
  getPickingList(query, value) {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      sorts: "createdDate:desc",
    };
    if (query && value) {
      params.search = query;
    }
    this.loading.pickingList = true;
    salesOrderServices
      .getPickingListList(params)
      .then(data => {
        this.dataListPickingList = data.data;
      })
      .finally(() => (this.loading.pickingList = false));
  }

  getDeliveryOrder(valueSearch: string) {
    let params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch) {
      params.search = `documentNumber~*${valueSearch}*`;
    }
    this.loading.deliveryOrder = true;
    salesOrderServices
      .getDeliveryOrderList(params)
      .then(data => {
        data.data.forEach((dataObject, index) => (dataObject.key = index));
        this.dataListDeliveryOrder = data.data;
      })
      .finally(() => (this.loading.deliveryOrder = false));
  }

  assignSearch(key, value, operator): string {
    if (key === "deliveryOrderNumber" && value)
      return operator + `secureId~${value}`;
    else if (key === "customerName" && value)
      return operator + `customer.secureId~${value}`;
    else if (key === "pickingListNumber" && value)
      return operator + `pickingList.documentNo~${value}`;
    else return "";
  }

  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach(key => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }

  async findData(pagination): Promise<void> {
    try {
      this.form.validateFields((err, res) => {
        if (err) return;
        const params: RequestQueryParamsModel = {
          page: pagination ? this.page - 1 : 0,
          limit: this.limit,
          sorts: "createdDate:desc",
        };
        params.search = this.dynamicSearch(res);
        if (
          this.modelForm.date &&
          Array.isArray(this.modelForm.date) &&
          this.modelForm.date.length > 0
        ) {
          const timeFrom = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[0])
                .set({
                  hour: 0,
                  minute: 0,
                  second: 0,
                })
                .utcOffset("+07")
                .format()
            : "";
          const timeTo = this.modelForm.date?.length
            ? this.moment(this.modelForm.date[1])
                .set({
                  hour: 23,
                  minute: 59,
                  second: 59,
                })
                .utcOffset("+07")
                .format()
            : "";
          const searchDate = `date>=${timeFrom}_AND_date<=${timeTo}`;
          if (params.search) {
            params.search += `_AND_${searchDate}`;
          } else {
            params.search = `${searchDate}`;
          }
        }

        this.loading.find = true;
        salesOrderServices
          .getDeliveryOrderList(params)
          .then(data => {
            data.data.forEach((dataMap, index) => {
              dataMap.key = index;
              dataMap.date = dataMap.date
                ? moment(dataMap.date).format(DEFAULT_DATE_FORMAT)
                : "";
              dataMap["referenceNumber"] = dataMap.salesOrderNumber
                ? dataMap.salesOrderNumber
                : dataMap.workOrderNumber;
            });
            this.dataSource = data.data;
            this.totalElements = data.totalElements;
          })
          .finally(() => (this.loading.find = false));
      });
    } catch (error) {
      this.$message.error(Messages.PROCESS_FAIL);
    }
  }
}
