var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_delivery_order") } },
    [
      _c(
        "a-form",
        _vm._b(
          { attrs: { layout: "vertical", form: _vm.form } },
          "a-form",
          _vm.formItemLayout,
          false
        ),
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.pickingListNumber.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.pickingListNumber.decorator,
                              expression:
                                "formRules.pickingListNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.pickingListNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.pickingListNumber.placeholder
                            ),
                            showSearch: true,
                            loading: _vm.loading.pickingList,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getPickingList(
                                "documentNo~*" + value + "*",
                                value
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataListPickingList, function(data, index) {
                          return _c(
                            "a-select-option",
                            {
                              key: index,
                              attrs: { value: data.pickingListNo }
                            },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + data.pickingListNo) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s("" + data.pickingListNo) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.deliveryOrderNumber.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value:
                                _vm.formRules.deliveryOrderNumber.decorator,
                              expression:
                                "formRules.deliveryOrderNumber.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.deliveryOrderNumber.name,
                            placeholder: _vm.$t(
                              _vm.formRules.deliveryOrderNumber.placeholder
                            ),
                            showSearch: true,
                            loading: _vm.loading.deliveryOrder,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getDeliveryOrder(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListDeliveryOrder, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " +
                                        _vm._s("" + data.documentNumber) +
                                        " "
                                    )
                                  ]),
                                  _vm._v(
                                    " " + _vm._s("" + data.documentNumber) + " "
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.customerName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.customerName.decorator,
                              expression: "formRules.customerName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.customerName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.customerName.placeholder
                            ),
                            showSearch: true,
                            loading: _vm.loading.customer,
                            "option-filter-prop": "children",
                            "filter-option": _vm.filterOption,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getCustomer(value)
                            }
                          }
                        },
                        _vm._l(_vm.dataListCustomer, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(
                                      " " + _vm._s("" + data.fullName) + " "
                                    )
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.fullName) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.date.label) } },
                    [
                      _c("a-range-picker", {
                        staticClass: "w-100",
                        attrs: {
                          ranges: {
                            Today: [_vm.moment(), _vm.moment()],
                            "This Month": [
                              _vm.moment(),
                              _vm.moment().endOf("month")
                            ]
                          },
                          format: _vm.DEFAULT_DATE_FORMAT,
                          type: "date"
                        },
                        on: { change: _vm.onRangePickerChange },
                        model: {
                          value: _vm.modelForm.date,
                          callback: function($$v) {
                            _vm.$set(_vm.modelForm, "date", $$v)
                          },
                          expression: "modelForm.date"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.clearItems } }, [
                        _vm._v(" " + _vm._s(this.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", loading: _vm.loading.find },
                          on: {
                            click: function($event) {
                              return _vm.findData(false)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(this.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "a-row",
        { attrs: { gutter: [16, 16] } },
        [
          _c(
            "a-col",
            { attrs: { span: 24, align: "end" } },
            [
              _vm.$can("create", "delivery-order")
                ? _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.createNew }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_create_new")) + " ")]
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 24 } },
            [
              _c("TableCustom", {
                attrs: {
                  idtable: "table1",
                  dataSource: _vm.dataSource,
                  columns: _vm.columnsTable,
                  scroll: { x: "calc(700px + 50%)" },
                  paginationcustom: true,
                  defaultPagination: false
                },
                on: {
                  "on-view": _vm.handleClickEdit,
                  "on-columnClicked": _vm.handleClickColumn
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12 } },
            [
              _c("Pagination", {
                attrs: {
                  total: _vm.totalElements,
                  pageSizeSet: _vm.limit,
                  idPagination: "pagination1"
                },
                on: {
                  "response-pagesize-change": _vm.responsePageSizeChange,
                  "response-currentpage-change": _vm.responseCurrentPageChange
                }
              })
            ],
            1
          ),
          _c(
            "a-col",
            { attrs: { span: 12, align: "end" } },
            [
              _c(
                "a-tag",
                {
                  staticStyle: { "font-size": "13px" },
                  attrs: { color: "grey" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("lbl_total_found")) +
                      " : " +
                      _vm._s(_vm.totalElements) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }